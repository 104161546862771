/* eslint-disable no-undef */
import React from 'react';
import { withFormik } from 'formik';
import * as Yup from 'yup';
import styled from 'styled-components';
import swal from 'sweetalert';
import Heading from './elements/Heading';

const Section = styled.div`
  .columns {
    margin-left: -0.75rem !important;
    margin-right: -0.75rem !important;
  }
  input,
  textarea {
    margin-top: 1rem;
    border-color: #e8edf0;
    box-shadow: none;
    border-radius: 0;
  }
  input:active,
  .input:focus,
  .textarea:active,
  .textarea:focus {
    border-color: ${(props) => props.theme.backgroundInputColor};
    box-shadow: none;
  }
  input ::placeholder,
  textarea ::placeholder {
    color: ${(props) => props.theme.backgroundInputColor};
    font-size: ${(props) => props.theme.fontSizeSmall}px;
    font-weight: 400;
  }
  button {
    background: ${(props) => props.theme.textColorLite} !important;
    color: ${(props) => props.theme.darkAccent} !important;
    border: 1px solid ${(props) => props.theme.darkAccent} !important;
    transition: 0.5s all ease-in-out;
    :hover {
      color: ${(props) => props.theme.textColorLite} !important;
      border: 1px solid ${(props) => props.theme.mainBrandColor} !important;
      background: ${(props) => props.theme.mainBrandColor} !important;
    }
  }
`;

const encode = (data) => {
  return Object.keys(data)
    .map((key) => `${encodeURIComponent(key)}=${encodeURIComponent(data[key])}`)
    .join('&');
};

const ContactForm = ({
  values,
  touched,
  errors,
  handleSubmit,
  handleChange,
  handleBlur,
}) => {
  return (
    <Section>
      <Heading centered>Get in Touch</Heading>
      <form data-netlify="true" name="contact" onSubmit={handleSubmit}>
        <div className="columns ">
          <div className="column">
            <input
              className="input is-medium"
              type="text"
              name="name"
              id="name"
              placeholder="Name"
              value={values.name}
              onChange={handleChange}
              onBlur={handleBlur}
            />
            {errors.name && touched.name && (
              <p className="help is-danger">{errors.name}</p>
            )}
          </div>
          <div className="column">
            <input
              className="input is-medium"
              type="text"
              name="email"
              id="email"
              placeholder="Email"
              value={values.email}
              onChange={handleChange}
              onBlur={handleBlur}
            />
            {errors.email && touched.email && (
              <p className="help is-danger">{errors.email}</p>
            )}
          </div>
        </div>
        <div>
          <textarea
            className="textarea"
            name="message"
            id="message"
            placeholder="Message"
            rows={6}
            defaultValue=""
            value={values.message}
            onChange={handleChange}
            onBlur={handleBlur}
          />
          {errors.message && touched.message && (
            <p className="help is-danger">{errors.message}</p>
          )}
        </div>
        <div className="mt-5">
          <button
            className="button is-primary is-medium"
            type="button"
            onClick={handleSubmit}
          >
            Send message
          </button>
        </div>
      </form>
    </Section>
  );
};

export default withFormik({
  mapPropsToValues: () => ({
    name: '',
    email: '',
    message: '',
  }),
  validationSchema: Yup.object().shape({
    name: Yup.string().required('Name is required!'),
    email: Yup.string()
      .email('Invalid email address')
      .required('Email is required!'),
    message: Yup.string().required('Message is required!'),
  }),
  handleSubmit: (values, { setSubmitting }) => {
    const handleFormSubmit = (e) => {
      fetch('/', {
        method: 'POST',
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
        body: encode({ 'form-name': 'contact', ...values }),
      })
        .then(() => swal('We Contact You Soon!, thank you!'))
        .catch((error) => console.log(error));

      e.preventDefault();
    };
    handleFormSubmit(values);
    setSubmitting(false);
  },
  displayName: 'ContactForm', // helps with React DevTools
})(ContactForm);
